import { mdiFileOutline, mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },
  {
    title: 'Canvas',
    icon: mdiFileOutline,
    to: 'canvas',
  },
]
