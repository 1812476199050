<template>
  <v-fade-transition mode="out-in">
    <div class="switcher">
      <v-switch
          class="theme_switcher"
          :key="isDark" v-model="switch1"
          color="#58addd" inset
          value="true"
          @click="isDark = !isDark"
      >
      </v-switch>
      <v-icon class="theme_switcher_icon">{{ isDark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}</v-icon>
    </div>
  </v-fade-transition>
</template>

<script>
import { ref } from "@vue/composition-api";
import { mdiWeatherNight, mdiWeatherSunny } from "@mdi/js";
import useAppConfig from "@core/@app-config/useAppConfig";

export default {
  setup() {
    const { isDark } = useAppConfig();
    const switch1 = ref(true);

    return {
      isDark,
      switch1,
      icons: { mdiWeatherNight, mdiWeatherSunny },
    };
  },
};
</script>

<style>
.switcher {
  display: flex;
  flex-direction: space-between;
}

.v-icon__svg {
  height: 20px;
  width: 20px;
}
</style>
