import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      layout: "content",
    },
  },
  {
    path: "/premium",
    name: "premium",
    beforeEnter(to, from, next) {
      window.open(`https://lottieads.io/premium/`, "_blank");
    },
  },
  {
    path: "/Canvas",
    name: "canvas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "canvas" */ "@/views/Canvas.vue"),
    meta: {
      layout: "content",
    },
  },
  // {
  //   path: '/second-page',
  //   name: 'second-page',
  //   component: () => import('@/views/SecondPage.vue'),
  //   meta: {
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/login',
  //   name: 'auth-login',
  //   component: () => import('@/views/Login.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  // {
  //   path: '*',
  //   redirect: 'error-404',
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
