import { canNavigate } from "@/plugins/acl/routeProtection";
import Vue from "vue";
import VueRouter from "vue-router";
import apps from "./apps";
import dashboard from "./dashboard";
import pages from "./pages";
import userInterface from "./user-interface";
Vue.use(VueRouter);

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: "/",
    redirect: (to) => {
      // const userData = JSON.parse(localStorage.getItem('userData'))
      // const userRole = userData && userData.role ? userData.role : null

      // if (userRole === 'admin') return { name: 'dashboard-crm' }
      // if (userRole === 'client') return { name: 'page-access-control' }

      return { name: "auth-login", query: to.query };
    },
  },
  {
    name: "google-comp",
    path: "/google-comp",
    component: () => import("@/components/GoogleComp"),
    props: true,
  },
  {
    path: "/premium",
    name: "premium",
    beforeEnter(to, from, next) {
      window.open(`https://lottieads.io/premium/`, "_blank");
    },
  },
  {
    name: "facebook-comp",
    path: "/facebook-comp",
    component: () => import("@/components/FacebookComp.vue"),
    props: true,
  },
  // {
  //   path: '/',
  //   redirect: 'home',
  // },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      layout: "content",
      requiredAuth: true,
    },
  },
  {
    path: "/",
    name: "add-project",
    component: () => import("@/components/AddProject.vue"),
    meta: {
      layout: "content",
      requiredAuth: true,
    },
  },
  {
    path: "/canvas",
    name: "canvas",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "canvas" */ "@/views/Canvas.vue"),
    meta: {
      layout: "content",
      requiredAuth: true,
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      requiredAuth: false,
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
      requiredAuth: false,
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/Register.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
      requiredAuth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      layout: "blank",
      resource: "Public",
      redirectIfLoggedIn: true,
      requiredAuth: false,
    },
  },
  ...dashboard,
  ...userInterface,
  ...apps,
  ...pages,
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

//routing protection
router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("jwt");
  if (to.path !== "/login" && to.meta.requiredAuth && !isLoggedIn) {
    next("/login");
  } else if (from.path === "/login" && to.meta.requiredAuth && isLoggedIn) {
    console.log("this hit");
    next();
  } else {
    next();
  }
});

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
// router.beforeEach((to, _, next) => {
//   //const userData = localStorage.getItem('userData')
//
//   //const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
//   const isLoggedIn = localStorage.getItem('jwt')
//   console.log('found token', localStorage.getItem('jwt'))
//
//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({name: 'auth-login', query: {marketplace: to.query.marketplace}})
//
//     // If logged in => not authorized
//     //return next({ name: 'misc-not-authorized' })
//
//     // return next({ name: 'misc-not-authorized' })
//   }
//
//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     if (isLoggedIn) {
//       next()
//     }
//
//     return next("/")
//   }
// })

export default router;
