export default [
  {
    title: "My Designs",
    to: "home",
  },
  {
    title: "Save As New",
    to: "premium",
  },
  {
    title: "Create New Project",
    to: "home",
  },
];
