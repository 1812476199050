import { mdiCheckboxBlankCircleOutline } from "@mdi/js";

const themeConfig = {
  app: {
    name: "LOTTIES",
    logo: require("@/assets/images/svg/logo.svg"),
    isDark: false,
    isRtl: false,
    contentLayoutNav: "horizontal", // vertical, horizontal
    routeTransition: "scroll-x-transition", // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: "semi-dark", // default, bordered, semi-dark
    contentWidth: "full",
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
        ! In Content Layout Horizontal Nav type `hidden` value won't work
        ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
        */
    type: "static", // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: "static", // fixed, static, hidden
  },
  themes: {
    light: {
      primary: "#9155FD",
      accent: "#d9d8e4",
      accentLight: "#ffffff",
      myColor: "#d9d8e4",
      canvasBorder: "#e6e5e9",
      accentLightHeader: "#EBEAEC",
      lottieLight: "#B394F7",
      mdiColor: "#918D98",
      svgColor:
        "invert(22%) sepia(11%) saturate(1684%) hue-rotate(205deg) brightness(92%) contrast(90%)",
      lottieDark: "#774BD2",
      accentGrey: "#EBEAEC",
      accentGreyText: "#BABABA",
      accentLightGrey: "#e7e3fc",
      menuHeaders: "#f4f5fa",
      dropZoneEditing: "#8A8D93",
      previewOverlay: "#f4f5fa",
      secondary: "#8A8D93",
      success: "#56CA00",
      info: "#16B1FF",
      warning: "#FFB400",
      error: "#FF4C51",
      layersMenu: "#d9d7e3",
      premiumText: "#3333",
      layerBottomLine: "#E7E6EA",
      dropDownMenu: "#FFFFFF",
    },
    dark: {
      premiumText: "#71cadd",
      layersMenu: "#3c3a57",
      primary: "#9155FD",
      mdiColor: "#ABA9C1",
      svgColor:
        "invert(87%) sepia(0%) saturate(0%) hue-rotate(199deg) brightness(98%) contrast(66%);",
      accent: "#3c3a57",
      myColor: "#3C3A58",
      canvasBorder: "#302D4B",
      accentLight: "#3c3a57",
      accentLightHeader: "#3c3a57",
      lottieLight: "#B394F7",
      lottieDark: "#774BD2",
      menuHeaders: "#3c3a57",
      accentGrey: "#EBEAEC",
      accentGreyText: "#64666d",
      accentLightGrey: "#e7e3fc",
      previewOverlay: "#64666d",
      dropZoneEditing: "#b7b3c7",
      secondary: "#8A8D93",
      success: "#56CA00",
      info: "#16B1FF",
      warning: "#FFB400",
      error: "#FF4C51",
      layerBottomLine: "#494560",
      dropDownMenu: "#494560"
    },
  },
};

export default themeConfig;
