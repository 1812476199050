export default [
  {
    title: 'My Designs',
    to: 'home',
  },
  {
    title: 'New Project',
    //to: 'canvas',
  },
]
