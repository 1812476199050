import axios from 'axios'
//import json from "qs";
const instance = axios.create({headers: { "Authorization": "Bearer " + localStorage.getItem("jwt")}})

export default {
    async save(data) {
        return await axios.post( '/api/project/save', data,
            {headers: { Authorization: "Bearer " + localStorage.getItem("jwt")}})
    },

    async load(id) {
        return (await instance.get('/api/project/load/'+id)).data
    },
    async fetchLottieOrImageFromUrl(url) {
        return (await axios.get(url)).data
    },
    async saveProject(data) {
        return await axios.post( '/api/project/saveproject', data,
            {headers: { Authorization: "Bearer " + localStorage.getItem("jwt")}})
    },
    async loadProject() {
        return (await axios.get('api/project/loadproject',
            {headers: { Authorization: "Bearer " + localStorage.getItem("jwt")}})).data
    },
    async registerUser(data) {
        return await instance.post('api/user/register', data)
    },
    async loginGoogleUser(data) {
        return await instance.post('api/user/loginGoogleUser', data)
    },
    async loginFacebookUser(data) {
        return await instance.post('api/user/loginFacebookUser', data)
    },
    async loginUser(data) {
        return await instance.post('api/user/login', data)
    },
    async deleteProject(data) {
        return await axios.post( '/api/project/deleteproject', data,
            {headers: { Authorization: "Bearer " + localStorage.getItem("jwt")}})
    },
    async getUserData() {
        console.log("getUserData")
        return (await axios.get('api/userData',
            {headers: { Authorization: "Bearer " + localStorage.getItem("jwt")}})).data
    },
    async createNewProjectFromTemplate() {
        return (await axios.get('/api/project/createFromTemplate',
            {headers: { Authorization: "Bearer " + localStorage.getItem("jwt")}})).data
    },

}

