import '@/plugins/vue-composition-api'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/index'
import store from './store'
import VueDraggableResizable from 'vue-draggable-resizable'
import contenteditable from 'vue-contenteditable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import '@/styles/styles.scss'
import McFontpicker from '@mikk3lro/mc-fontpicker';
import '@mikk3lro/mc-fontpicker/dist/mc-fontpicker.css';
import Lottie from "vue-lottie";
import VueAnalytics from 'vue-analytics'
import Hotjar from 'vue-hotjar'
import VueGtm from '@gtm-support/vue2-gtm';

Vue.component("lottie", Lottie);
Vue.component("McFontpicker", McFontpicker);
Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.config.productionTip = false
Vue.use(contenteditable)
Vue.use(VueAnalytics, {
    id: 'G-BN3B2GTL3L',
    router,
});
Vue.use(Hotjar, {
    id: '3042155',
});
Vue.use(VueGtm, {
id: 'GTM-WQZQZQK',
debug: true,
autoInit: true,
autoLog: true,
    dragIndicator: true,
    collapse_all: true,
dataLayerName: 'dataLayer',
dataLayerOptions: {
    push: true,
    debug: true,
    autoInit: true,
    autoLog: true,
    dataLayerName: 'dataLayer',
},
});

export const bus = new Vue()

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
